<template>
  <div class="mt-4 ">
    <button @click="$router.go(-1)" class="btn btn-sm btn-default">Back</button>
  <div class="card shadow w-100 p-4 mt-2 text-center">
    <h1>Coming Soon...</h1>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Coming Soon",
  components: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {},
};
</script>
